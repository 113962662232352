import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
} from "react-share"

import styles from "./SocialShare.module.scss"
import "../utils/fontawesome"

const SocialShare = ({ socialConfig, tags }) => (
  <div className={styles.postSocial}>
    <FacebookShareButton
      url={socialConfig.config.url}
      className={`${styles.button} ${styles.isOutlined} ${styles.isRounded} ${styles.facebook}`}
    >
      <span className="icon">
        <FontAwesomeIcon icon={["fab", "facebook"]} />
      </span>
      <span className="text"> Facebook</span>
    </FacebookShareButton>
    <TwitterShareButton
      url={socialConfig.config.url}
      className={`${styles.button} ${styles.isOutlined} ${styles.isRounded} ${styles.twitter}`}
      title={socialConfig.config.title}
      via={socialConfig.twitterHandle.split("@").join("")}
      hashtags={tags}
    >
      <span className="icon">
        <FontAwesomeIcon icon={["fab", "twitter"]} />
      </span>
      <span className="text"> Twitter</span>
    </TwitterShareButton>
    <LinkedinShareButton
      url={socialConfig.config.url}
      className={`${styles.button} ${styles.isOutlined} ${styles.isRounded} ${styles.linkedin}`}
      title={socialConfig.config.title}
    >
      <span className="icon">
        <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
      </span>
      <span className="text"> LinkedIn</span>
    </LinkedinShareButton>
    <RedditShareButton
      url={socialConfig.config.url}
      className={`${styles.button} ${styles.isOutlined} ${styles.isRounded} ${styles.reddit}`}
      title={socialConfig.config.title}
    >
      <span className="icon">
        <FontAwesomeIcon icon={["fab", "reddit-alien"]} />
      </span>
      <span className="text"> Reddit</span>
    </RedditShareButton>
    <WhatsappShareButton
      url={socialConfig.config.url}
      className={`${styles.button} ${styles.isOutlined} ${styles.isRounded} ${styles.whatsapp}`}
      title={socialConfig.config.title}
    >
      <span className="icon">
        <FontAwesomeIcon icon={["fab", "whatsapp"]} />
      </span>
      <span className="text"> WhatsApp</span>
    </WhatsappShareButton>
  </div>
)

SocialShare.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
SocialShare.defaultProps = {
  tags: [],
}

export default SocialShare
