import React from "react"
import { graphql, Link } from "gatsby"
import contentParser from "gatsby-wpgraphql-inline-images"
import Img from "gatsby-image"
import { Context as ResponsiveContext } from "react-responsive"
import { css } from "@emotion/core"

// Components
import SEO from "../components/seo"
import { Layout } from "../components/Layout"
import BannerShape from "../components/BannerShape"
import SocialShare from "../components/SocialShare"

// Custom styles & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styles from "./post.module.scss"

const Post = props => {
  const {
    data: {
      wpcontent: { post },
      site: {
        siteMetadata: { siteUrl, twitterHandle },
      },
    },
    pageContext: {
      pluginOptions: { wordPressUrl, uploadsUrl },
    },
  } = props

  const {
    uri,
    title,
    excerpt,
    content,
    featuredImage,
    image,
    author,
    date,
    readingTime,
    categories,
    tags,
  } = post

  const d = new Date(date)
  const mm = d.toLocaleString("default", { month: "long" })
  const dd = d.getDate()
  const yyyy = d.getFullYear()

  // Strip HTML tags in excerpt
  const strippedExcerpt = excerpt.replace(/(<([^>]+)>)/gi, "")

  const keyTags = tags.nodes.map(tag => tag.name)

  return (
    <Layout>
      <SEO
        title={title}
        description={strippedExcerpt}
        image={image.node.imageFile.childImageSharp.resize}
        keywords={keyTags}
        pathname={props.location.pathname}
      />
      <ResponsiveContext.Provider value={{ maxWidth: 575 }}>
        <BannerShape />
      </ResponsiveContext.Provider>
      <ResponsiveContext.Provider value={{ minWidth: 576 }}>
        <Container
          fluid
          css={css`
            padding: 0;
            @media (max-width: 575px) {
              display: none;
            }
          `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="40 0 950 100"
            preserveAspectRatio="none"
            width="auto"
            height="12rem"
            css={css`
              transform: rotateY(180deg);
            `}
          >
            <path
              fill="#316d49"
              d="M0,0c0,0,0,6,0,6.7c0,18,240.2,93.6,615.2,92.6C989.8,98.5,1000,25,1000,6.7c0-0.7,0-6.7,0-6.7H0z"
            ></path>
          </svg>
        </Container>
      </ResponsiveContext.Provider>
      <Container className={styles.postContainer}>
        <Row className="justify-content-md-center">
          <Col sm="12" md="10" lg="8">
            <h1
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className={styles.detailContainer}>
              <p>
                {author.node.name} | {`${mm} ${dd}, ${yyyy}`}
              </p>
              <p>{`${readingTime} min read`}</p>
            </div>

            <Img
              fluid={featuredImage.node.imageFile.childImageSharp.fluid}
              objectFit="cover"
              alt={title}
            />
            <div className="content-container">
              {contentParser({ content }, { wordPressUrl, uploadsUrl })}
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="justify-content-md-center">
          <Col sm="12" md="10" lg="8">
            <p className={styles.chipsLabel}>Category</p>
            <div className={styles.chipsContainer}>
              {categories.nodes.map(category => (
                <Link
                  className={styles.chip}
                  to={`/blogs${category.uri}`}
                  key={category.id}
                >
                  {category.name}
                </Link>
              ))}
            </div>
          </Col>
        </Row>
        {tags.nodes.length !== 0 && (
          <Row className="justify-content-md-center">
            <Col sm="12" md="10" lg="8">
              <p className={styles.chipsLabel}>Tags</p>
              <div className={styles.chipsContainer}>
                {tags.nodes.map(tag => (
                  <Link
                    className={styles.chip}
                    to={`/blogs${tag.uri}`}
                    key={tag.id}
                  >
                    {tag.name}
                  </Link>
                ))}
              </div>
            </Col>
          </Row>
        )}
        <Row className="justify-content-md-center mb-5 mt-5">
          <Col sm="12" md="10" lg="8">
            <p className={styles.chipsLabel}>Share</p>
            <SocialShare
              socialConfig={{
                twitterHandle,
                config: {
                  url: `${siteUrl}${uri}`,
                  title,
                },
              }}
              tags={keyTags}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query GET_POST($id: ID!) {
    site {
      siteMetadata {
        siteUrl
        twitterHandle
      }
    }
    wpcontent {
      post(id: $id, idType: ID) {
        uri
        title(format: RENDERED)
        excerpt(format: RENDERED)
        content(format: RENDERED)
        featuredImage {
          node {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        image: featuredImage {
          node {
            sourceUrl
            imageFile {
              childImageSharp {
                resize(width: 1080) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
        date
        readingTime
        categories {
          nodes {
            id
            name
            uri
          }
        }
        tags {
          nodes {
            id
            name
            uri
          }
        }
      }
    }
  }
`
